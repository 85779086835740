import React, { useState, useEffect, useRef, useCallback } from 'react';
import DID_API from './api.json';
import './App_possible.css';
// import idle_avatar_video from './Ashley 30 sec silence.mp4';
// import idle_avatar_video from './Ashley 15 secs silence.mp4';
// import idle_avatar_video from './Ashley 15 sec silence 020407.mp4';
// import idle_avatar_video from './Ashley 15 sec silence white.mp4';
import { URL } from "./constants";
// import idle_avatar_video from './Ashley 15 sec silence D5D7E2.mp4';
import idle_avatar_video from './Ashley Silence (1).mp4';

const RTCPeerConnection = (
  window.RTCPeerConnection ||
  window.webkitRTCPeerConnection ||
  window.mozRTCPeerConnection
).bind(window);

const Avatar = ({ avatarSpeechInput, setAvatarSpeechInput, lastStreamedMessage, setLastStreamedMessage, isStreamingDone, setIsStreamingDone, isReadyToStream, setIsReadyToStream, token }) => {
  const [peerConnection, setPeerConnection] = useState(null);
  const peerConnectionRef = useRef(null);
  const [streamId, setStreamId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [statsIntervalId, setStatsIntervalId] = useState(null);
  const [iceGatheringStatus, setIceGatheringStatus] = useState('');
  const [iceStatus, setIceStatus] = useState('');
  const [peerStatus, setPeerStatus] = useState('');
  const [signalingStatus, setSignalingStatus] = useState('');
  const [streamingStatus, setStreamingStatus] = useState('');
  const videoRef = useRef(null);

  useEffect(() => {
    // playIdleVideo();
    setTimeout(connectHandler, 500);

    return () => {
      clearInterval(statsIntervalId);
    };
  }, []);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const connectHandler = async () => {
    // console.log('connectHandler triggered');
    if (peerConnection && peerConnection.connectionState === 'connected') {
      return;
    }
    closePC();
  
    const sessionResponse = await fetchWithRetries(URL + '/api/avatar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify({
        url: DID_API.url,
        service: `${DID_API.service}/streams`,
        data: {},
      }),
    });
  
    const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
    setStreamId(newStreamId);
    setSessionId(newSessionId);
  
    try {
      const pc = new RTCPeerConnection({ iceServers });
      pc.onicecandidate = (event) => onIceCandidate(event, pc, newStreamId, newSessionId);
      pc.oniceconnectionstatechange = () => onIceConnectionStateChange(pc);
      pc.onconnectionstatechange = onConnectionStateChange;
      pc.onsignalingstatechange = onSignalingStateChange;
      pc.ontrack = onTrack;
      setPeerConnection(pc);
      peerConnectionRef.current = pc;
  
      const answer = await createPeerConnection(pc, offer);
      let body = JSON.stringify({
        answer: answer,
        session_id: newSessionId,
      });

      await fetch(URL + '/api/avatar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          url: DID_API.url,
          service: `${DID_API.service}/streams/${newStreamId}/sdp`,
          data: body,
        }),
      });
  
      playIdleVideo();
      const elements = document.querySelectorAll('.streaming-component');
      elements.forEach(element => {
        element.style.display = 'block';
      });
  
      return () => {
        clearInterval(statsIntervalId);
      };
    } catch (e) {
      // console.log('error during streaming setup', e);
      closePC();
      return;
    }
  };

  const startHandler = async () => {
    if (peerConnection && (peerConnection.signalingState === 'stable' || peerConnection.iceConnectionState === 'connected')) {
      // console.log('startHandler triggered');
      // console.log('avatarSpeechInput in startHandler:', avatarSpeechInput);
  
      await fetchWithRetries(URL + '/api/avatar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          url: DID_API.url,
          service: `${DID_API.service}/streams/${streamId}`,
          data: {
            script: {
              type: "text",
              input: avatarSpeechInput,
              provider: {
                type: "elevenlabs",
              }
            },
            ...(DID_API.service === 'clips' && {
              background: {
                color: '#D5D7E2',
              },
            }),
            config: {
              stitch: true,
            },
            session_id: sessionId,
          },
        }),
      });
    }
  };

  const debouncedStartHandler = useCallback(debounce(startHandler, 500), [startHandler]);

  useEffect(() => {
    // console.log('isStreamingDone for lastStreamedMessage:', isStreamingDone);
    // console.log('avatarSpeechInput for lastStreamedMessage:', avatarSpeechInput);
    // console.log('isReadyToStream for lastStreamedMessage:', isReadyToStream);
    if (
      avatarSpeechInput &&
      avatarSpeechInput !== "" &&
      isStreamingDone === true
    ) {
      debouncedStartHandler();
    }
    // console.log('isStreamingDone for lastStreamedMessage:', isStreamingDone);
    // console.log('avatarSpeechInput for lastStreamedMessage:', avatarSpeechInput);
    // console.log('isReadyToStream for lastStreamedMessage:', isReadyToStream);
  }, [isStreamingDone]);

  const destroyHandler = async () => {
    await fetch(URL + '/api/avatar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify({
        url: DID_API.url,
        service: `${DID_API.service}/streams/${streamId}`,
        data: {
          method: 'DELETE',
          session_id: sessionId,      },
      }),
    });
  
    stopAllStreams();
    closePC();
  };
  

  const onIceGatheringStateChange = () => {
    // console.log('onIceGatheringStateChange triggered');
    setIceGatheringStatus(peerConnectionRef.current.iceGatheringState);
  };

  const onIceCandidate = (event, pc, streamId, sessionId) => {
    // console.log('onIceCandidate triggered');
    if (event.candidate && streamId) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
      let sendIceCandidateBody = JSON.stringify({
        candidate,
        sdpMid,
        sdpMLineIndex,
        session_id: sessionId,
      });
      fetch(URL + '/api/avatar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          url: DID_API.url,
          service: `${DID_API.service}/streams/${streamId}/ice`,
          data: sendIceCandidateBody,      }),
      });
    }
  };

  const onIceConnectionStateChange = (pc) => {
    // console.log('onIceConnectionStateChange triggered');
    setIceStatus(pc.iceConnectionState);
    if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'closed') {
      stopAllStreams();
      closePC();
    }
  };

  const onConnectionStateChange = () => {
    // console.log('onConnectionStateChange triggered');
    setPeerStatus(peerConnectionRef.current.connectionState);
  };

  const onSignalingStateChange = () => {
    // console.log('onSignalingStateChange triggered');
    setSignalingStatus(peerConnectionRef.current.signalingState);
  };

  const videoIsPlayingRef = useRef(false);

  const onTrack = (event) => {
    // console.log('ontrack triggered');
    if (event.track.kind === 'video') {
      let lastBytesReceivedRef = { current: 0 };
      let lastVideoStatusChangeTimeRef = { current: 0 };
      const streamWaitTime = 1500;
  
      setStatsIntervalId(
        setInterval(async () => {
          const stats = await peerConnectionRef.current.getStats(event.track);
          let bytesReceived = 0;
          stats.forEach((report) => {
            if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
              bytesReceived = report.bytesReceived;
            }
          });
  
          const currentTime = Date.now();
          const timeSinceLastChange = currentTime - lastVideoStatusChangeTimeRef.current;
          const videoStatusChanged = bytesReceived > lastBytesReceivedRef.current;
  
          if (videoStatusChanged && videoIsPlayingRef.current === false && timeSinceLastChange > streamWaitTime) {
            lastBytesReceivedRef.current = bytesReceived;
            lastVideoStatusChangeTimeRef.current = currentTime;
            videoIsPlayingRef.current = true;
            onVideoStatusChange(videoIsPlayingRef.current, event.streams[0]);
          } else if (!videoStatusChanged && videoIsPlayingRef.current === true && timeSinceLastChange > streamWaitTime) {
            lastBytesReceivedRef.current = bytesReceived;
            lastVideoStatusChangeTimeRef.current = currentTime;
            videoIsPlayingRef.current = false;
            onVideoStatusChange(videoIsPlayingRef.current, event.streams[0]);
          } else {
            lastBytesReceivedRef.current = bytesReceived;
          }
        }, 200)
      );
    } else if (event.track.kind === 'audio') {
      setVideoElement(event.streams[0]);
    }
  };

  const onVideoStatusChange = (videoIsPlaying, stream) => {
    // console.log('onVideoStatusChange triggered');
    let status;
    if (videoIsPlaying) {
      status = 'streaming';
      setVideoElement(stream);
    } else {
      status = 'empty';
      playIdleVideo();
      clearInterval(statsIntervalId);
    }
    setStreamingStatus(status);
  };

  const createPeerConnection = async (pc, offer) => {
    await pc.setRemoteDescription(offer);
    const sessionClientAnswer = await pc.createAnswer();
    await pc.setLocalDescription(sessionClientAnswer);
    return sessionClientAnswer;
  };

  const setVideoElement = (stream) => {
    if (!stream) return;
    videoRef.current.srcObject = stream;
    videoRef.current.loop = false;
    videoRef.current.muted = false;
    videoRef.current.play().catch((error) => {
      console.error('Error playing video:', error);
    });
  };

  const playIdleVideo = () => {
    if (videoRef.current.srcObject) {
      videoRef.current.srcObject = null;
    }
    videoRef.current.src = idle_avatar_video;
    videoRef.current.loop = true;
    videoRef.current.muted = true;
    videoRef.current.play().catch((error) => {
      console.error('Error playing idle video:', error);
    });
  };


  const stopAllStreams = () => {
    if (videoRef.current.srcObject) {
      // console.log('stopping video streams');
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const closePC = (pc = peerConnection) => {
    if (!pc) return;
    // console.log('stopping peer connection');
    pc.close();
    pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
    pc.removeEventListener('icecandidate', onIceCandidate, true);
    pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
    pc.removeEventListener('connectionstatechange', onConnectionStateChange, true);
    pc.removeEventListener('signalingstatechange', onSignalingStateChange, true);
    pc.removeEventListener('track', onTrack, true);
    clearInterval(statsIntervalId);
    // console.log('stopped peer connection');
    if (pc === peerConnection) {
      setPeerConnection(null);
    }
  };

  const maxRetryCount = 3;
  const maxDelaySec = 4;

  const fetchWithRetries = async (url, options, retries = 1) => {
    try {
      return await fetch(url, options);
    } catch (err) {
      if (retries <= maxRetryCount) {
        const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay));
        // console.log(`Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`);
        return fetchWithRetries(url, options, retries + 1);
      } else {
        throw new Error(`Max retries exceeded. error: ${err}`);
      }
    }
  };

  return (
    <div className="streaming-experience">
        <div class="streaming-component">
            <video
            ref={videoRef}
            class="streaming-video-element"
            autoPlay
            loop
            ></video>
        </div>
    </div>
  );
};

export default Avatar;