export const API_POLLING_INTERVAL = 5000;
// export const websiteURL = 'http://52.54.144.242:3003';
export const websiteURL = 'http://localhost:3003';
// export const URL = 'http://54.237.70.22';
// export const URL = 'https://possible-kiosk-backend2.jennai.com';
export const URL = 'https://kevin-dev-box.jennai.com';
// export const companyName = 'drloretta';
// export const companyName = 'shiseido';
// export const companyName = 'shiseido_demo';
// export const companyName = 'sokolin';
// export const companyName = 'piperwai';
export const companyName = 'possible';
export const enableDictation = false;
export const enablePDP = false;
// export const deployedDevice = 'conferenceScreen';
export const deployedDevice = 'other';
export const isOnShopify = true;
export const showReviews = false;
export const productNotAvailableString = 'Event No Longer Available';
export const companyData = {
	olukai: {
        cssFileName: './App_drloretta.css',
		productInfoFileName: './product_info__olukai.json',
		shortIntroMessage: 'olukai shortIntroMessage',
		longIntroMessage: 'olukai longIntroMessage',
		sampleMessageOne: 'black hiking shoes under $60',
		sampleMessageTwo: 'white beach walking shoes, comfortable',
	},
	drloretta: {
		cssFileName: './App_drloretta.css',
		productInfoFileName: './product_info_drloretta.json',
		shortIntroMessage: 'drloretta shortIntroMessage',
		longIntroMessage: 'drloretta longIntroMessage',
		sampleMessageOne: '',
		sampleMessageTwo: ''
    },
	shiseido: {
		cssFileName: './App_shiseido.css',
		productInfoFileName: './product_info_shiseido.json',
		shortIntroMessage: 'shiseido shortIntroMessage',
		longIntroMessage: 'shiseido longIntroMessage',
		sampleMessageOne: '',
		sampleMessageTwo: ''
    },
	sokolin: {
		cssFileName: './App_base.css',
		productInfoFileName: './product_info_sokolin.json',
		shortIntroMessage: 'sokolin shortIntroMessage',
		longIntroMessage: 'sokolin longIntroMessage',
		sampleMessageOne: '',
		sampleMessageTwo: ''
    },
	piperwai: {
		cssFileName: './App_piperwai.css',
		productInfoFileName: './product_info_piperwai.json',
		shortIntroMessage: "Hi, I'm Jenn! 👋 I'm your pit-care expert and shopping concierge. How can I help you?",
		longIntroMessage: "Hi, I'm Jenn! 👋 I'm your pit-care expert and shopping concierge. How can I help you?",
		sampleMessageOne: 'Why do my pits smell bad?',
		sampleMessageTwo: 'What ingredients are in your deoderant?'
    },
	possible: {
		cssFileName: './App_possible.css',
		productInfoFileName: './product_info_possible.json',
		shortIntroMessage: 'Hi, I am Jenn, your POSSIBLE conference concierge! 👋 How can I help you?',
		longIntroMessage: 'Hi, I am Jenn, your POSSIBLE conference concierge! 👋 How can I help you?',
		sampleMessageOne: 'When is Gary V speaking?',
		sampleMessageTwo: 'What is the best CMO AI event?'
    },
	base: {
		cssFileName: './App_base.css',
		productInfoFileName: './product_info.json',
		shortIntroMessage: "Hi, I'm Jenn! 👋 I'm your skincare expert & shopping concierge. How can I help you?",
		longIntroMessage: "Hi, I'm Jenn! 👋 I’m your skincare expert & shopping concierge.\n\nWhat can I do for you today? \n\n• A personalized skincare consultation. \n• Answer product questions. \n• Answer more technical skin questions. \n• Help with existing order. \n• Something else.",
		sampleMessageOne: '',
		sampleMessageTwo: ''
    }
}