import React, { useEffect } from 'react';
import logo from './logo.png';
import { URL } from "./constants";

const LoginPage = ({ onLogin }) => {
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');

    if (storedUsername && storedPassword) {
      // If username and password are stored in localStorage, log in automatically
      handleLogin(storedUsername, storedPassword);
    } else {
      // If username and password are not stored, generate new ones and sign up
      const generatedUsername = generateUsername();
      const generatedPassword = generatePassword();
      handleSignup(generatedUsername, generatedPassword);
    }
  }, []);

  const generateUsername = () => {
    // Generate a random username
    const randomString = Math.random().toString(36);
    return `user_${randomString.slice(0, 7)}`;
  };

  const generatePassword = () => {
    // Generate a random password
    const randomString = Math.random().toString(36);
    return `pass_${randomString.slice(0, 7)}1!`;
  };

  const handleLogin = (username, password) => {
    fetch(URL + '/authentication/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if ('token' in data) {
          onLogin(username, data['token']);
        } else {
          // If login fails, generate new credentials and sign up
          const generatedUsername = generateUsername();
          const generatedPassword = generatePassword();
          handleSignup(generatedUsername, generatedPassword);
        }
      });
  };

  const handleSignup = (username, password) => {
    fetch(URL + '/authentication/auth/signup/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password
      }),
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if ('token' in data) {
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);
          onLogin(username, data['token']);
        } else {
          // If signup fails, generate new credentials and try again
          const generatedUsername = generateUsername();
          const generatedPassword = generatePassword();
          handleSignup(generatedUsername, generatedPassword);
        }
      });
  };

  const goToMarketingSite = () => {
    window.location = "https://jennai.com";
  };

  return (
    <div className='login-page-container'>
      <div className='login-page-message'>
        Welcome to Jenn!
      </div>
      <img className="logo" src={logo} onClick={goToMarketingSite} />
      <div className="login-page">
        <div className="login-loading-message">Logging in automatically...</div>
      </div>
    </div>
  );
};

export default LoginPage;