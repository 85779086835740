// import './App_base.css';
// import './App_piperwai.css';
// import './App_drloretta.css';
import './App_possible.css';
// import './App_shiseido.css';
import React, { useState, useEffect, useRef } from 'react';
import Navigation from './Navigation';
// import AudioVisualizer from './AudioVisualizer';
import ChatInterface from './ChatInterface';
import LoginPage from './LoginPage';
import LoginPageAutoSignin from './LoginPageAutoSignin';
// import Products from './Products';
// import QuantityAdjuster from './QuantityAdjusterShopify';
// import CheckoutButton from './CheckoutButtonShopify';
// import VideoPlayer from './VideoPlayer';
import { websiteURL, companyName, deployedDevice } from "./constants";
import Avatar from './Avatar';
import signage from './JennAI Signage Title.png';
import possible_logo from './possible_logo.png';


const App = () => {
  const [username, setUsername] = useState(null);
  const [token, setToken] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [showChat, setShowChat] = useState(companyName !== "possible" ? false : true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isStreamingDone, setIsStreamingDone] = useState(true)
  const [firstLoadedIn, setFirstLoadedIn] = useState(true)
  const [avatarSpeechInput, setAvatarSpeechInput] = useState('');
  const [isReadyToStream, setIsReadyToStream] = useState(false); // Initialize isReadyToStream to false


  const handleUser = (user, tok) => {
    // console.log(user)
    // console.log(tok)
    setUsername(user)
    setToken(tok)
  }

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const handleFullScreenChange = (isFull) => {
    setIsFullScreen(isFull);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (username === null && companyName !== "possible") {
    return <LoginPage onLogin={handleUser} />;
  } else if (username === null) {
    return <LoginPageAutoSignin onLogin={handleUser} />;
  } else {
    return (
      <div className="backdrop">
        { companyName !== "possible" && 
          <iframe 
            src={websiteURL}
            style={{ width: '100%', height: '100vh', border: 'none' }}
            sandbox="allow-scripts allow-same-origin"
            title="External Website"
          />
        }
        {/* <div className="christian-avatar-label">
          Christian.ai
        </div> */}
        <div className='signage-container'>
          <img className='signage' src={signage}/>
        </div>
        {/* <Avatar avatarSpeechInput={avatarSpeechInput} setAvatarSpeechInput={setAvatarSpeechInput} isStreamingDone={isStreamingDone} setIsStreamingDone={setIsStreamingDone} isReadyToStream={isReadyToStream} setIsReadyToStream={setIsReadyToStream} token={token} /> */}
        <img className="possible-logo" src={possible_logo}></img>
        <div className={`${showChat && !isFullScreen ? 'chat-interface-wrapper' : ' '}`}>
          <ChatInterface username={username} token={token} screenWidth={windowSize[0]} toggleChat={toggleChat} showChat={showChat} isFullScreen={isFullScreen} onFullScreenChange={handleFullScreenChange} isStreamingDone={isStreamingDone} setIsStreamingDone={setIsStreamingDone} firstLoadedIn={firstLoadedIn} setFirstLoadedIn={setFirstLoadedIn} loading={loading} setLoading={setLoading} setAvatarSpeechInput={setAvatarSpeechInput} avatarSpeechInput={avatarSpeechInput} isReadyToStream={isReadyToStream} setIsReadyToStream={setIsReadyToStream} deployedDevice={deployedDevice} />
        </div>
      </div>
    )
  }
};

export default App;